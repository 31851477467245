import { type ReactNode, useEffect, useState } from 'react'
import { ShimmerLoader } from './ShimmerLoader'
import { SkeletonLoader } from './SkeletonLoader'

/**
 * This component can be used to wrap content that is being fetched. It will animate
 * a progress bar while the content is loading.
 * @param children: One or more elements that we want to wrap.
 */
export function LoadingWrapper({
  children,
  isLoading = true,
  promptDelayInMs = 3000,
  type = 'shimmer',
}: {
  children?: ReactNode | ReactNode[]
  isLoading?: boolean
  promptDelayInMs?: number
  type?: 'skeleton' | 'shimmer'
}) {
  const [isPromptVisible, setIsPromptVisible] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (isLoading && !isPromptVisible) {
      timeout = setTimeout(() => setIsPromptVisible(true), promptDelayInMs)
    }
    return () => clearTimeout(timeout)
  }, [isLoading, isPromptVisible])

  if (isLoading) {
    return (
      <div className='grid grid-cols-1 gap-4 text-center'>
        {type === 'shimmer' ? (
          <ShimmerLoader isPromptVisible={isPromptVisible} />
        ) : (
          <SkeletonLoader />
        )}
      </div>
    )
  }

  return <>{children}</>
}

const CardsSkeleton = () => (
  <div className='flex w-full flex-col gap-4'>
    <div className='h-24 w-full rounded-xl bg-neutral200'></div>
    <div className='h-32 w-[90%] rounded-xl bg-neutral200'></div>
    <div className='h-24 w-[95%] rounded-xl bg-neutral200'></div>
    <div className='h-32 w-[90%] rounded-xl bg-neutral200'></div>
    <div className='h-24 w-[95%] rounded-xl bg-neutral200'></div>
  </div>
)

export const ShimmerCards = () => (
  <div className='flex animate-pulse justify-center'>
    <CardsSkeleton />
  </div>
)

export const SearchSkeleton = () => {
  return (
    <div className='w-full'>
      <div className='mt-12 hidden animate-pulse justify-center gap-10 md:flex'>
        <div className='flex w-1/4 flex-col'>
          <div className='h-[600px] w-full rounded-xl bg-neutral200'></div>
        </div>
        <div className='w-3/4'>
          <CardsSkeleton />
        </div>
      </div>
      <div className='md:hidden'>
        <SkeletonLoader />
      </div>
    </div>
  )
}
