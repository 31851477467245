export const mockTrialConditions = [
  {
    childTerms: [],
    condition: {
      colloquialAcronym: 'N/A',
      colloquialTerm: 'Prostate Cancer',
      colloquialTermStripped: 'Prostate Cancer',
      createdAt: new Date('2021-09-06T22:10:41.665Z'),
      drugbankId: 'DBC000001',
      drugbankTableId: 1,
      icd10Id: '',
      id: 167,
      medraId: '',
      meshId: 'D000011471',
      name: 'Prostatic Neoplasms',
      preferredTerm: 'Prostate Cancer',
      snomedId: '',
      synonyms: [''],
      therapeuticAreaId: null,
      umlsParentCategories: [],
      umlsTreeNumber: '',
      updatedAt: new Date('2021-09-18T20:19:06.105Z'),
    },
    conditionId: 167,
    createdAt: new Date('2021-09-06T23:06:37.996Z'),
    id: 8634,
    inConditionsModule: true,
    isApplyFlowSelectable: true,
    isMtaComparable: true,
    isPrimaryCondition: false,
    nctId: 'NCT01420965',
    parentTerms: [],
    trialEditId: null,
    updatedAt: new Date('2021-09-17T23:29:23.784Z'),
  },
]
